
require("@rails/ujs").start();

import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';

const header = document.querySelector('header');
header.querySelector('a.burger').addEventListener('click', (e) => {
  e.preventDefault();
  header.classList.toggle('open');
});

const footer = document.querySelector('footer');
footer.querySelectorAll('.nav .title').forEach((el) => {
  el.addEventListener('click', (e) => {
    e.target.closest('.nav').classList.toggle('open');
  });
});

/*
 * Homepage
 */
document.querySelectorAll('.homepage-hero .video').forEach((el) => {
  el.addEventListener('click', (e) => {
    if (el.classList.contains('active'))
      return;
    const video = el.querySelector('video');
    if (e.target == video)
      return;
    el.classList.add('active');
    video.setAttribute('controls', 'controls');
    video.play();
  });
});

document.querySelectorAll('.explore-videos .swiper').forEach((el) => {
  new Swiper(el, {
    modules: [Navigation, Pagination],
    slidesPerView: 'auto',
    spaceBetween: 14,
    pagination: {
      el: el.querySelector('.swiper-pagination'),
    },
    navigation: {
      nextEl: el.querySelector('.swiper-button-next'),
      prevEl: el.querySelector('.swiper-button-prev'),
    },
    breakpoints: {
      400: {
      },
      768: {
      },
      1420: {
        spaceBetween: 25
      }
    }
  });
});

document.querySelectorAll('.block-with-title .swiper').forEach((el) => {
  new Swiper(el, {
    modules: [Navigation, Pagination],
    slidesPerView: 1,
    spaceBetween: 40,
    pagination: {
      el: el.querySelector('.swiper-pagination'),
    },
    navigation: {
      nextEl: el.querySelector('.swiper-button-next'),
      prevEl: el.querySelector('.swiper-button-prev'),
    },
    breakpoints: {
      400: {
        centeredSlides: false,
        slidesPerView: 1,
      },
      768: {
        centeredSlides: false,
        slidesPerView: 2,
      },
      1280: {
        slidesPerView: 'auto',
        spaceBetween: 30
      }
    }
  });
});

document.querySelectorAll('.block-with-title:not(.media) .collapse-button').forEach((el) => {
  el.addEventListener('click', (e) => {
    e.preventDefault();
    const c = e.target.closest('.block-with-title').classList, t = c.contains('expanded');
    c.toggle('collapsed', t);
    c.toggle('expanded', !t);
  });
});

/*
 * Videos page
 */
let videoModalVisible = false;

document.querySelectorAll('.videos .video .thumb').forEach((el) => {
  el.addEventListener('click', (e) => {
    videoModalVisible = true;

    e.preventDefault();
    const modal = document.querySelector('.modal.video-modal');
    const v = document.createElement('video');
    v.setAttribute('controls', 'controls');
    v.setAttribute('autoplay', 'autoplay');
    v.setAttribute('src', el.dataset['videoUrl']);
    modal.querySelector('.content .video').appendChild(v);
    modal.style.display = 'flex';

    window.history.pushState(null, null, el.getAttribute('href'));
  });
});

document.querySelectorAll('.modal.video-modal').forEach((el) => {
  el.addEventListener('click', (e) => {
    if (e.target.closest('a.close') || e.target.classList.contains('modal')) {
      videoModalVisible = false;

      e.preventDefault();
      const modal = document.querySelector('.modal.video-modal');
      modal.querySelector('.content .video').innerHTML = '';
      modal.style.display = 'none';
      window.history.pushState(null, null, '/videos');
    }
  });
});

/*window.addEventListener('popstate', function(e) {
  console.log(e);
  if (inBlogArticle && window.location.pathname == '/blog')
    document.querySelector('.article.expanded a.collapse-button').click();
  else if (inMediaArticle && window.location.pathname == '/what-were-lovin')
    document.querySelector('.block-with-title.media.show-article .collapse-button').click();
  else if (videoModalVisible && window.location.pathname == '/videos')
    document.querySelector('.modal.video-modal a.close').click();
  else
    history.back();
});*/

/*
 * Media
 */
let inMediaArticle = true;

document.querySelectorAll('.block-with-title.media .collapse-button').forEach((el) => {
  el.addEventListener('click', (e) => {
    inMediaArticle = false;
    e.preventDefault();
    e.target.closest('.block-with-title').classList.remove('show-article');
    window.history.pushState(null, null, '/what-were-lovin');
  });
});

document.querySelectorAll('.block-with-title.media a.swiper-slide[data-id]').forEach((el) => {
  el.addEventListener('click', (e) => {
    inMediaArticle = true;
    e.preventDefault();
    const block = el.closest('.block-with-title');
    block.querySelectorAll('.article').forEach((a) => {
      a.style.display = 'none';
    })
    const id = el.dataset['id'];
    block.querySelectorAll(`.article[data-id="${id}"]`).forEach((a) => {
      a.style.display = 'flex';
    })
    block.classList.add('show-article');
    block.scrollIntoView();
    window.history.pushState(null, null, el.getAttribute('href'));
  });
});

/*
 * Blogs
 */
let inBlogArticle = false;

document.querySelectorAll('.blog .article a.readmore').forEach((el) => {
  el.addEventListener('click', (e) => {
    inBlogArticle = true;

    e.preventDefault();
    e.target.closest('.article').classList.add('expanded');
    e.target.closest('.article').scrollIntoView();
    window.history.pushState(null, null, el.getAttribute('href'));
  });
});

document.querySelectorAll('.blog .article .collapse-button').forEach((el) => {
  el.addEventListener('click', (e) => {
    inBlogArticle = false;

    e.preventDefault();
    e.target.closest('.article').classList.remove('expanded');
    window.history.pushState(null, null, '/blog');
  });
});

/*
 * Team
 */
document.querySelectorAll('.yab .member').forEach((el) => {
  el.addEventListener('click', (e) => {
    e.target.closest('.member').classList.toggle('flip');
  });
});

/*
 * Contact
 */
const contactForm = document.getElementById('new_contact');
if (contactForm) {
  contactForm.addEventListener('ajax:success', (e) => {
    e.preventDefault();
    if (e.detail[0].status == 'success') {
      contactForm.style.display = 'none';
      document.querySelector('.success-message').style.display = 'flex';
    }
  });
}

/*
 * Quiz
 */
const quiz = document.querySelector('.modal.quiz');
if (quiz) {
  quiz.querySelectorAll('.answers svg').forEach((el) => {
    el.addEventListener('click', () => {
      quiz.querySelectorAll('.answers svg.selected').forEach((v) => v.classList.remove('selected'));
      el.classList.toggle('selected');
    });
  });

  let questionIndex = 0;
  const answers = [];
  quiz.querySelector('.question > a.btn').addEventListener('click', (e) => {
    e.preventDefault();
    const selectedAnswer = quiz.querySelector('.answers svg.selected');
    if (!selectedAnswer)
      return;

    answers.push([window.quizQuestions[questionIndex], selectedAnswer.classList.contains('yes')]);
    questionIndex++;

    if (questionIndex >= window.quizQuestions.length) {
      // Submit to server
      fetch('/quiz/submit', {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
        },
        referrerPolicy: "no-referrer",
        body: JSON.stringify({ submission: { quiz_id: window.quizId, submission: JSON.stringify(answers) } }),
      }).then((e) => e.json()).then((e) => {
        if (e.status === 'success') {
          // quiz.querySelector('.title_text').style.display = 'none';
          quiz.querySelector('.question').style.display = 'none';
          quiz.querySelector('.finished').style.display = 'block';
        }
      });

    } else {
      quiz.querySelector('.question .text').innerHTML = window.quizQuestions[questionIndex];
      quiz.querySelector('.title_text span').innerText = questionIndex + 1;
      selectedAnswer.classList.remove('selected');
    }
  });
}
